<template>
  <div class="container-fluid ml-10">
    <div>
      <div class="row">
        <div class="col-md-11 col-xl-12">
          <div class="card card-custom mb-3">
            <div
              class="card-header"
              style="border-color: var(--bordercolorform)"
            >
              <div class="card-title">
                <div class="mr-4 h1">
                  <router-link to="/ticketing"
                    ><div><b-icon-arrow-left></b-icon-arrow-left></div
                  ></router-link>
                </div>
                <h3 class="card-label cardLabelTiket">Support Ticket</h3>
              </div>
              <div class="card-toolbar"></div>
            </div>
            <div style="margin: 12px">
              <DxDataGrid
                :ref="my - data - grid"
                :data-source="dataSource"
                key-expr="id"
                :show-borders="true"
                :word-wrap-enabled="false"
                :column-auto-width="false"
                :remote-operations="true"
                :column-hiding-enabled="true"
                @exporting="onExporting"
              >
                <DxSearchPanel :visible="true" />
                <DxFilterRow :visible="false" />
                <DxExport :enabled="true" />
                <DxColumn data-field="site_id" caption="Site ID" :width="100" />
                <DxColumn
                  data-field="site_location"
                  caption="Location"
                  :width="150"
                />
                <DxColumn
                  data-field="number_of_tickets"
                  caption="Jumlah Problem"
                />
                <DxColumn :width="100" type="buttons" caption="Aksi">
                  <DxButton
                    icon="fa fa-eye"
                    hint="Lihat"
                    :onClick="lihatSemua"
                  />
                </DxColumn>
                <DxPaging :page-size="10" />
                <DxPager
                  :visible="true"
                  :show-page-size-selector="showPageSizeSelector"
                  :allowed-page-sizes="5"
                />
              </DxDataGrid>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="editearly"
      size="xl"
      ref="my-modaledit"
      title="Detail Tiket"
      hide-footer
      centered
    >
      <b-form class="mt-4">
        <DxDataGrid
          :ref="dataGridRefKey"
          :data-source="dataSourceView"
          key-expr="id"
          :show-borders="true"
          :word-wrap-enabled="false"
          :column-auto-width="false"
          :remote-operations="true"
          :column-hiding-enabled="false"
        >
          <DxExport :enabled="true" />
          <DxColumn data-field="ticket_id" caption="Ticket ID" />
          <DxColumn data-field="site_id" caption="Site ID" />
          <DxColumn data-field="site_location" caption="Location" />
          <DxColumn data-field="problem" caption="Problem" />
          <DxColumn data-field="status" caption="Status" />
          <DxPaging :page-size="10" />
          <DxPager
            :visible="true"
            :show-page-size-selector="showPageSizeSelector"
            :allowed-page-sizes="pageSizes"
          />
        </DxDataGrid>
      </b-form>
      <!-- <pre>{{ infoModal.content }}</pre> -->
    </b-modal>
  </div>
</template>

<script>
import {
  DxButton,
  DxDataGrid,
  DxSearchPanel,
  DxFilterRow,
  DxColumn,
  DxExport
} from "devextreme-vue/data-grid";
import { validationMixin } from "vuelidate";
import { exportDataGrid } from "devextreme/excel_exporter";
import WebService from "../../../core/services/api";
import EventBus from "../../../core/services/common/EventBus";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import { isNotEmpty } from "../../../core/Apputil/Dx";
import CustomStore from "devextreme/data/custom_store";
const dataGridRefKey = "my-data-grid-view";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists

    if (
      Array.isArray(loadOptions["filter"]) &&
      loadOptions["filter"].length > 0 &&
      loadOptions["filter"][0] === "name"
    ) {
      params += "&nama=" + loadOptions["filter"][2];
    }
    const user = JSON.parse(localStorage.getItem("user"));
    const headers = { Authorization: `Bearer ${user.access_token}` };
    return fetch(
      process.env.VUE_APP_URL_LOCAL + `/ticket-support-summary${params}`,
      {
        headers
      }
    )
      .then(response => response.json())
      .then(data => {
        return {
          data: data.data,
          totalCount: data.totalCount,
          summary: data.summary,
          groupCount: data.groupCount
        };
      })
      .catch(() => {
        throw "Data Loading Error";
      });
  }
});
export default {
  mixins: [validationMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxDataGrid,
    DxSearchPanel,
    DxFilterRow,
    DxColumn,
    DxExport,
    DxButton
  },
  data() {
    return {
      form: {
        site_id: "",
        ws_id: "",
        location: "",
        boxsn: "",
        classname: "",
        address: "",
        province: "",
        district: "",
        zip_code: "",
        pkt: "",
        customer: ""
      },
      formedit: {
        site_id: "",
        ws_id: "",
        location: "",
        boxsn: "",
        classname: "",
        address: "",
        province: "",
        district: "",
        zip_code: "",
        pkt: "",
        customer: ""
      },
      editearly: false,
      dataGridRefKey,
      dataSource: store,
      dataSourceView: [],
      optionprov: [],
      optionscustomer: [],
      detaildata: "",
      detaildataadv: "",
      wsid: "",
      searchID: "",
      location: "",
      showModalAdd: false,
      showModalEdit: false,
      boxSn: "",
      className: "",
      searchQuery: null,
      editvisit: false,
      showModalEditadv: false,
      itemsuser: [],
      itemsadvanced: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      infoModaladv: {
        id: "info-modal",
        title: "",
        content: ""
      },
      selectedstatdev: null,
      selectedstatusvisit: null,

      optionsstatusvisit: [
        { value: null, text: "Select Status Device" },
        { value: "a", text: "Active" },
        { value: "b", text: "Non Active" },
        { value: "c", text: "Pending Device" },
        { value: "d", text: "--" }
      ],
      optionsstatdev: [
        { value: null, text: "Select Status Visit" },
        { value: "a", text: "Priority" },
        { value: "b", text: "Priority" },
        { value: "c", text: "Priority" },
        { value: "d", text: "Priority" }
      ]
    };
  },
  validations: {
    form: {
      site_id: {
        required
      },
      ws_id: {
        required
      },
      location: {
        required
      },
      boxsn: {
        required
      },
      classname: {
        required
      },
      address: {
        required
      },
      province: {
        required
      },
      district: {
        required
      },
      zip_code: {
        required
      },
      pkt: {
        required
      },
      customer: {
        required
      }
    },
    formedit: {
      site_id: {
        required
      },
      ws_id: {
        required
      },
      location: {
        required
      },
      boxsn: {
        required
      },
      classname: {
        required
      },
      address: {
        required
      },
      province: {
        required
      },
      district: {
        required
      },
      zip_code: {
        required
      },
      pkt: {
        required
      },
      customer: {
        required
      }
    }
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.items.filter(item => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(v => item.fields.toLowerCase().includes(v));
        });
      } else {
        return this.items;
      }
    }
  },
  mounted() {
    this.getDataTable();
    this.getDataTableAdvanced();
    this.getallprovince();
    this.getAllCustomer();
  },
  methods: {
    getallprovince() {
      WebService.getrefProv().then(async res => {
        const roles = res.data.data;
        let selectedOptions;
        selectedOptions = [];
        roles.map(i => {
          return selectedOptions.push({
            text: i.name,
            value: i.name
          });
        });
        this.optionprov = selectedOptions;
      });
    },
    getAllCustomer() {
      WebService.getRefCustomer().then(async res => {
        const roles = res.data.data;
        let selectedOptions;
        selectedOptions = [];
        roles.map(i => {
          return selectedOptions.push({
            text: i.name,
            value: i.id
          });
        });
        this.optionscustomer = selectedOptions;
      });
    },
    onSubmitEdit() {
      this.$v.formedit.$touch();
      if (this.$v.formedit.$anyError) {
        return;
      }

      let id = this.formedit.id;
      let data = {
        id: this.formedit.id,
        address: this.formedit.address,
        box_sn: this.formedit.boxsn,
        customer_id: parseInt(this.formedit.customer),
        district: this.formedit.district,
        location_name: this.formedit.location,
        pkt: this.formedit.pkt,
        province: this.formedit.province,
        site_class_name: this.formedit.classname,
        site_id: this.formedit.site_id,
        wsid: this.formedit.ws_id,
        zip_code: this.formedit.zip_code
      };

      console.log(data);
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.editLocalsite(data, id).then(
            res => {
              console.log(res);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil Diubah",
                showConfirmButton: false,
                timer: 1500
              });
              this.getDataTableAdvanced();
              this.resetFormEdit();
              this.showModalEdit = false;
            },
            error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Error",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
              this.getDataTableAdvanced();
              this.showModalEdit = false;
            }
          );
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    lihatSemua(data) {
      this.editearly = true;
      const store = new CustomStore({
        key: "id",
        load: function(loadOptions) {
          let params = "?";
          [
            "skip",
            "take",
            "requireTotalCount",
            "requireGroupCount",
            "sort",
            // "filter",
            "totalSummary",
            "group",
            "groupSummary"
          ].forEach(function(i) {
            if (i in loadOptions && isNotEmpty(loadOptions[i])) {
              params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            }
          });
          params = params.slice(0, -1);

          // search by name if exists

          if (
            Array.isArray(loadOptions["filter"]) &&
            loadOptions["filter"].length > 0 &&
            loadOptions["filter"][0] === "name"
          ) {
            params += "&nama=" + loadOptions["filter"][2];
          }
          const user = JSON.parse(localStorage.getItem("user"));
          const headers = { Authorization: `Bearer ${user.access_token}` };
          return fetch(
            process.env.VUE_APP_URL_LOCAL +
              `/ticket-support-items/${data.row.data.id}${params}`,
            {
              headers
            }
          )
            .then(response => response.json())
            .then(data => {
              return {
                data: data.data,
                totalCount: data.totalCount,
                summary: data.summary,
                groupCount: data.groupCount
              };
            })
            .catch(() => {
              throw "Data Loading Error";
            });
        }
      });
      this.dataSourceView = store;
      // dataSourceView
    },
    onExporting(e) {
      e.component.beginUpdate();
      const workbook = new Workbook();

      exportDataGrid({
        component: e.component
      })
        .then(function() {
          workbook.xlsx.writeBuffer().then(function(buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "Pending_Ticket.xlsx"
            );
          });
        })
        .then(function() {
          e.component.endUpdate();
        });

      e.cancel = true;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let body = {
        address: this.form.address,
        box_sn: this.form.boxsn,
        customer_id: parseInt(this.form.customer),
        district: this.form.district,
        location_name: this.form.location,
        pkt: this.form.pkt,
        province: this.form.province,
        site_class_name: this.form.classname,
        site_id: this.form.site_id,
        wsid: this.form.ws_id,
        zip_code: this.form.zip_code
      };
      console.log(body);
      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.addlocalsite(body).then(
            res => {
              console.log(res);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.getDataTableAdvanced();
              this.resetForm();
              this.showModalAdd = false;
            },
            error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Error",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
              this.getDataTableAdvanced();
              this.showModalAdd = false;
            }
          );
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    hapus(value) {
      const id = value.row.data.id;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin Data Dihapus?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "OK",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.deletelocalsite(id).then(
            res => {
              console.log(res);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.getDataTableAdvanced();
              this.resetForm();
              this.showModalAdd = false;
            },
            error => {
              console.log(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data Gagal Dihapus",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
              this.getDataTableAdvanced();
              this.showModalAdd = false;
            }
          );
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formedit[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        id: null,
        site_id: "",
        ws_id: "",
        location: "",
        boxsn: "",
        classname: "",
        address: "",
        province: "",
        district: "",
        zip_code: "",
        pkt: "",
        customer: ""
      };
      this.$nextTick(() => {
        this.$bvModal.hide("add-new-modal");
      });
    },
    resetFormEdit() {
      this.formEdit = {
        id: null,
        site_id: "",
        ws_id: "",
        location: "",
        boxsn: "",
        classname: "",
        address: "",
        province: "",
        district: "",
        zip_code: "",
        pkt: "",
        customer: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("edit-user-modal");
      });
    },
    onchangeSiteId() {
      const filter = "site_id";
      const keyword = this.searchID ? this.searchID : "";
      if (this.searchID !== "") {
        var filteredData = this.itemsadvanced.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsadvanced = filteredData;
      } else {
        this.getDataTableAdvanced();
      }
    },
    onchangeWSId() {
      const filter = "wsid";
      const keyword = this.wsid ? this.wsid : "";
      if (this.wsid !== "") {
        var filteredData = this.itemsadvanced.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsadvanced = filteredData;
      } else {
        this.getDataTableAdvanced();
      }
    },
    onchangeLocation() {
      const filter = "location_name";
      const keyword = this.location ? this.location : "";
      if (this.location !== "") {
        var filteredData = this.itemsadvanced.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsadvanced = filteredData;
      } else {
        this.getDataTableAdvanced();
      }
    },
    onCancel() {
      this.showModalAdd = false;
      this.resetForm();
    },
    onchangeBox() {
      const filter = "box_sn";
      const keyword = this.boxSn ? this.boxSn : "";
      if (this.boxSn !== "") {
        var filteredData = this.itemsadvanced.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsadvanced = filteredData;
      } else {
        this.getDataTableAdvanced();
      }
    },
    onchangeClassName() {
      const filter = "site_class_name";
      const keyword = this.className ? this.className : "";
      if (this.className !== "") {
        var filteredData = this.itemsadvanced.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsadvanced = filteredData;
      } else {
        this.getDataTableAdvanced();
      }
    },
    info(item, index, button) {
      console.log(item, index, button);
      this.infoModal.title = `Detail`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    infoadv(item, index, button) {
      console.log(item, index, button);
      this.infoModaladv.title = `Detail`;
      this.infoModaladv.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModaladv.id, button);
    },
    getDataTable() {
      let databody = { type: "basic" };
      WebService.getallSiteData(databody).then(
        res => {
          this.itemsuser = res.data.data;
          this.totalRows = res.data.totalCount;
        },
        error => {
          console.log(error);
          this.itemsuser = [];
          this.totalRows = 0;
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    getDataTableAdvanced() {
      let databody = JSON.stringify([{ selector: "created_at", desc: true }]);
      WebService.getlocalsiteAll(encodeURI(databody)).then(
        res => {
          this.itemsadvanced = res.data.data;
          this.totalRows = res.data.totalCount;
        },
        error => {
          console.log(error);
          this.itemsadvanced = [];
          this.totalRows = 0;
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    hapusvisit(index) {
      this.items.splice(index, 1);
    },
    iconEdit(e) {
      this.showModalEdit = true;
      const data = e.row.data;
      console.log(e.row.data);
      this.formedit.id = data.id;
      this.formedit.site_id = data.site_id;
      this.formedit.ws_id = data.wsid;
      this.formedit.location = data.location_name;
      this.formedit.boxsn = data.box_sn;
      this.formedit.classname = data.site_class_name;
      this.formedit.address = data.address;
      this.formedit.province = data.province;
      this.formedit.district = data.district;
      this.formedit.zip_code = data.zip_code;
      this.formedit.pkt = data.pkt;
      this.formedit.customer = data.customer_id;
    },
    iconAdvanced(e) {
      this.showModalEditadv = true;
      this.detaildataadv = e.row.data;
    }
  }
};
</script>

<style>
.btn-add {
  width: 144px;
  height: 44px;
  background: #c60b09;
  border-radius: 8px;
  color: #fffafa;
}
.btn-cancel {
  width: 144px;
  height: 44px;
  border: 1px solid #c60b09;
  box-sizing: border-box;
  border-radius: 8px;
  color: #c60b09;
}
</style>
